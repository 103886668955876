import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
        <div className="headline">{data.site.siteMetadata.home.title}</div>
        <div 
          className="primary-content" 
          dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description}}
        />
      {//  <Link to='/about' className="button -primary">About This Blog &rarr;</Link>
      }
            <div className="blog-post-container" style = {{width: '80%'}}>
            <center><button className='squareTagButton tagsBox'><Link to='/tags/no-deposit'> No Deposit</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/crypto'>Crypto</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/money-transfer'>Money Transfer</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/cash'>Cash</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/mobile'>Mobile</Link> </button>
      </center>
      </div>
      </div>
    )}
  />
)