import React, { useState } from "react"
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges , totalCount },
  },
}) => {

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  
  const emptyQuery = ""
  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })
  const handleInputChange = event => {
    const query = event.target.value
    // this is how we get all of our posts
    const dummyPosts = edges || []
     // return all filtered posts
    const filteredData = dummyPosts.filter(post => {
      // destructure data from post frontmatter
      const { description, title, tags } = post.node.frontmatter
      if(description != null){
      return (
        // standardize data with .toLowerCase()
        // return true if the description, title or tags
        // contains the query string
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags && tags
          .join("") // convert tags from an array to string
          .toLowerCase()
          .includes(query.toLowerCase()))
      )
        } else return (       
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags && tags
          .join("") // convert tags from an array to string
          .toLowerCase()
          .includes(query.toLowerCase())));
    })
    // update state according to the latest query and results
    setState({
      query, // with current query string from the `Input` event
      filteredData, // with filtered data from posts.filter(post => (//filteredData)) above
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const finalPosts = hasSearchResults ? filteredData.map(edge => <PostLink key={edge.node.id} post={edge.node} />) : Posts
  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <HeroHeader/>
      {/*
      <div className="blog-post-container" style = {{width: '80%'}}>
      <center><button className='squareTagButton tagsBox'><Link to='/tags/no-deposit'> No Deposit</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/crypto'>Crypto</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/money-transfer'>Money Transfer</Link> </button>
      <button className='squareTagButton tagsBox'><Link to='/tags/cash'>Cash</Link> </button>
      </center>
      </div>*/
      }
      <h2> Blog Posts   <div className ="postCount">{finalPosts.length}</div> &darr; <Link to='/tags' style={{ textAlign: 'right'}} >View All Tags</Link></h2>
      
      <input
              className="searchInput"
              type="text"
              aria-label="Search"
              placeholder="Type to filter posts..."
              onChange={handleInputChange}
          />
      <div className="grids">
        {finalPosts}
      </div>
    </Layout>
  )
}
// date(formatString: "MMMM DD, YYYY")
export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      totalCount
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(fromNow: true)
            path
            title
            thumbnail
            product
            description
            tags
          }
        }
      }
    }
  }
`
